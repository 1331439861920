.w-max-100px {
  max-width: 500px !important;
}

.w-min-100px {
  min-width: 300px !important;
}

.h-70px {
  height: 70px !important;
}

.text-title {
  color: #667a85;
}

.invalid-feedback {
  position: absolute;
  margin-top: 0.2rem !important;
}

.form-label {
  margin-bottom: 0.2rem !important;
}

.offline-container {
  filter: grayscale(100%);
}

#offline {
  filter: grayscale(100%);
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

#offline-contrainer {
  width: 100%;
  height: 100%;
}

.table-wrapper {
  max-height: 100px;
  overflow: auto;
  display: inline-block;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.card-statistics {
  min-height: 10px;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .card-statistics {
    min-height: 100px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
// @keyframes fade {
//     0% { opacity: 0 }
//     100% { opacity: 1 }
//   }

.content {
  max-width: 1920px;
  //   opacity: 1;
  //   animation: fade 1s linear;
}

// .trs {
//   transition: all 0.5s;
//   opacity: 100;
// animation: fadeIn;
// }

// .trs.preEnter {
//     opacity: 100;
//       animation: fadeOut;

// }
// .trs.exiting {
//   opacity: 0;
//     animation: fadeIn;
// }

// .trs.exited {
//   display: none;
// }

@keyframes fadeIn {
  from {
    opacity: 0;
    // transform: translate(-10px, 0);
  }
  to {
    opacity: 1;
    // transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    // transform: translate(0px, 0px);
  }
  to {
    // transform: translate(-10px, 0);
    opacity: 0;
  }
}

.fadeIn {
  animation: 1s fadeIn liner;
}

.fadeOut {
  animation: 1s fadeOut liner;
}
